<template>
  <div v-bind="$attrs">
    <div v-if="value">
      <span ref="countUp" v-if="numType == 'num'">
        {{ num || "---" }}
      </span>

      <span v-else-if="numType == 'time'">
        {{ num || "---" }}
      </span>
    </div>
    <div v-else>---</div>
  </div>
</template>

<script>
import { CountUp } from "countup.js";
export default {
  props: {
    value: [Number, String],
    animation_time: {
      type: Number,
      default: 1,
    },
    numType: {
      type: String,
      default: "num",
    },
  },
  name: "ChangeNum",
  data() {
    return {
      screenNum: this.value,
    };
  },
  computed: {
    num() {
      const { value, numType, screenNum } = this;
      let num = "";
      switch (numType) {
        case "time":
          let s = screenNum < 0 ? 0 : Math.floor(screenNum % 60);
          let m = screenNum < 0 ? 0 : Math.floor((screenNum / 60) % 60);
          num = screenNum
            ? (m < 10 ? "0" + m : m) + "'" + (s < 10 ? "0" + s : s) + "''"
            : "00'00''";
          break;
        default:
          num = value <= 0 ? 0 : value;
          break;
      }
      return num;
    },
  },
  methods: {
    async sleep(millisecond) {
      return new Promise(function (reslove) {
        setTimeout(reslove, millisecond);
      });
    },
  },
  watch: {
    async value(newValue, oldValue) {
      const countUp = new CountUp(this.$refs.countUp, newValue, {
        startVal: oldValue,
        duration: this.animation_time,
      });

      if (!countUp.error) {
        countUp.start();
      } else {
        // console.error(countUp.error);
      }

      if (this.numType == "time") {
        const totalTime = this.animation_time * 1000;
        const times = 20; //数字变化次数
        const intervalTime = Math.floor(totalTime / times);
        // 每次变化差值
        const distance = (newValue - oldValue) / times;

        for (let index = 0; index < times; index++) {
          this.screenNum += distance;
          await this.sleep(intervalTime);
        }
      }
    },
  },
};
</script>
